define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-editor", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="post-voting-comment-editor post-voting-comment-editor-{{@id}}">
    <PostVotingCommentComposer
      @onInput={{this.updateValue}}
      @raw={{@raw}}
      @onKeyDown={{this.onKeyDown}}
    />
  
    <DButton
      @action={{this.saveComment}}
      @disabled={{this.submitDisabled}}
      @label="post_voting.post.post_voting_comment.edit"
      @icon="pencil"
      class="btn-primary post-voting-comment-editor-submit"
    />
  
    <DButton
      @display="link"
      @action={{@onCancel}}
      @label="post_voting.post.post_voting_comment.cancel"
      class="post-voting-comment-editor-cancel"
    />
  </div>
  */
  {
    "id": "S9COkzsG",
    "block": "[[[10,0],[15,0,[29,[\"post-voting-comment-editor post-voting-comment-editor-\",[30,1]]]],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@onInput\",\"@raw\",\"@onKeyDown\"],[[30,0,[\"updateValue\"]],[30,2],[30,0,[\"onKeyDown\"]]]],null],[1,\"\\n\\n  \"],[8,[39,1],[[24,0,\"btn-primary post-voting-comment-editor-submit\"]],[[\"@action\",\"@disabled\",\"@label\",\"@icon\"],[[30,0,[\"saveComment\"]],[30,0,[\"submitDisabled\"]],\"post_voting.post.post_voting_comment.edit\",\"pencil\"]],null],[1,\"\\n\\n  \"],[8,[39,1],[[24,0,\"post-voting-comment-editor-cancel\"]],[[\"@display\",\"@action\",\"@label\"],[\"link\",[30,3],\"post_voting.post.post_voting_comment.cancel\"]],null],[1,\"\\n\"],[13]],[\"@id\",\"@raw\",\"@onCancel\"],false,[\"post-voting-comment-composer\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-editor.hbs",
    "isStrictMode": false
  });
  class PostVotingCommentEditor extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "value", [_tracking.tracked], function () {
      return this.args.raw;
    }))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "submitDisabled", [_tracking.tracked], function () {
      return true;
    }))();
    #submitDisabled = (() => (dt7948.i(this, "submitDisabled"), void 0))();
    updateValue(value) {
      this.value = value;
      this.submitDisabled = value.length < this.siteSettings.min_post_length || value.length > this.siteSettings.post_voting_comment_max_raw_length;
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateValue", [_object.action]))();
    onKeyDown(e) {
      if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
        this.saveComment();
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "onKeyDown", [_object.action]))();
    saveComment() {
      this.submitDisabled = true;
      const data = {
        comment_id: this.args.id,
        raw: this.value
      };
      return (0, _ajax.ajax)("/post_voting/comments", {
        type: "PUT",
        data
      }).then(this.args.onSave).catch(_ajaxError.popupAjaxError).finally(() => {
        this.submitDisabled = false;
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "saveComment", [_object.action]))();
  }
  _exports.default = PostVotingCommentEditor;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingCommentEditor);
});